import axios from "axios"; //axios引入
//引入封装的吐司，进度条等
import router from "@/router";
import store from "../store/index";
import { Toast } from "vant";
var msg = ""; //传过来的msg
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_APIURL, // api的base_url
  // baseURL: 'https://lsdj-wap-api.lanshididu.com', // api的base_url
  timeout: 15000 // 请求超时时间
});

// request请求拦截器
service.interceptors.request.use(
  config => {
    config.headers["channelCode"] = localStorage.getItem("channelCode") || "";
    config.headers["authorization"] ='Bearer '+ localStorage.getItem("authorization") || ""
    // 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dpblR5cGUiOiJtZW1iZXIiLCJsb2dpbklkIjoxNzkzODI3OTM0MDY3NTUyMjU3LCJyblN0ciI6InJJQzNFSWtEcEk4UGRNVU9zS1VTZWJjNDdDWFBqOEZLIiwidXNlcm5hbWUiOiIxNTgzMjE0MTQzNiIsIm1vYmlsZSI6IjE1ODMyMTQxNDM2In0.IHRAdkwJuLvdt-LqNzpPoPYNrz7J0T_5o7abhLlJfE8'
    // config.headers["cityCode"] = localStorage.getItem("cityCode") || "110100";

    if (config.hds == 1) {
      //上传图片
      config.headers["Content-Type"] = "multipart/form-data";
    } else if (config.hds == 2) {
      //json格式
      config.headers["Content-Type"] = "application/json;charset=UTF-8";
    } else {
      //键值对
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    }
    //请求的时候加入进度条（在这里可以传入进入条的图标、msg（封装时已默认加载中），还可以传入特定的值约束，比如：msg ==0 时，不展示）
    msg = config.msg;
    return config;
  },
  error => {
    //网络不通，跳转404页面
    console.log("request跳转404页面：" + error); // 跳转404页面
    Promise.reject(error);
  }
);

// respone返回拦截器
service.interceptors.response.use(
  response => {
    const res = response.data;
    if (res !== null) {
      //成功后的成功返回
      if (res.code === 10000) {
        return Promise.resolve(res);
      } else{
        Toast(res.msg || "系统错误");
      }
    } else {
      // 成功后的失败返回
      return Promise.reject(res);
    }
  },
  error => {
    console.log(error, "接口报错");
    if (
      error.code === "ECONNABORTED" &&
      error.message.indexOf("timeout") !== -1
    ) {
      //console.log('根据你设置的timeout/真的请求超时 判断请求现在超时了，你可以在这里加入超时的处理方案')
      var originalRequest = error.config;
      return service.request(originalRequest); //例如再重复请求一次
    }else if(error.response.data.code == 1005){
      store.commit("empowerControl", true);
      localStorage.removeItem("authorization");
    }else{
      Toast(error.response.data.msg || "系统错误");
    }
  }
);

export default service;
