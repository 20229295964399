<template>
  <div class="phoneLogin">
    <!-- 项目弹窗 -->
    <van-popup
      v-model="$store.state.phoneLoginShow"
      closeable
      position="bottom"
      :style="{ minHeight: '50%' }"
      round
    >
      <div class="phoneLoginPopup">
        <!-- <svg-icon icon-class="close" class="closeIcon" @click="$store.state.phoneLoginShow"></svg-icon> -->
        <p class="title">
          <img src="../assets/img/logo.jpg" alt="" class="logoImg" />
          <span class="title-text">绑定手机号享受更多服务</span>
        </p>
        <div class="phoneInput">
          <p>
            <svg-icon icon-class="phone" class="inputIcon"></svg-icon>
            <van-field
              v-model="phone"
              placeholder="请输入手机号"
              maxlength="11"
              size="small"
              type="digit"
              :border="false"
            />
            <span class="getCodeBtn" @click="codeClick">{{
              codeTiming == 60 ? "获取验证码" : codeTiming + " S"
            }}</span>
          </p>
          <p>
            <svg-icon
              icon-class="code"
              class="inputIcon"
              style="margin-left: -3px;"
            ></svg-icon>
            <van-field
              v-model="code"
              placeholder="请输入验证码"
              size="small"
              type="digit"
              :border="false"
            />
          </p>
        </div>
        <div class="confirmBtn" @click="clickConfirm">立即绑定</div>
        <!-- <div class="privacy-div" @click.stop="checked=!checked">
          <van-checkbox v-model="checked" checked-color="#ee0a24" icon-size="14px"/>
          <span class="privacy-span">已阅读并同意<font @click.stop="privacyClick('1')">《 用户协议 》</font>和<font @click.stop="privacyClick('2')">《 隐私政策 》</font></span>
        </div> -->
      </div>
    </van-popup>
  </div>
</template>

<script>
import { bindMobile } from "@/api/index";
import { sendSmsCode } from "../api/index";
var codeTimingTimeout;
export default {
  data() {
    return {
      checked: false,
      code: "",
      codeTiming: 60,
      phone: ""
    };
  },
  created() {},
  methods: {
    // 确认点击
    clickConfirm() {
      if (!this.phone) {
        this.$toast("请输入手机号");
      } else if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$toast("手机号输入有误");
      } else if (!this.code) {
        this.$toast("请输入验证码");
      }
      // else if(!this.checked){
      //   this.$toast("请阅读并同意协议")
      // }
      else {
        let mappingKey = sessionStorage.getItem("mappingKey") || "";
        let data = {
          openId: localStorage.getItem("openId"),
          mobile: this.phone,
          // authCode:'',
          authCode: this.code,
          mappingKey: mappingKey
        };
        bindMobile(data).then(res => {
          if (res.code == 10000) {
            this.$toast("手机号绑定成功");
            localStorage.setItem("isBindMobile", 1);
            localStorage.setItem("authorization", res.data.authorization);
            this.$store.commit("phoneLoginControl", false);
          }
        });
      }
    },
    // 点击发送验证码
    codeClick() {
      if (!this.phone) {
        this.$toast("请输入手机号");
      } else if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$toast("手机号输入有误");
      } else {
        if (this.codeTiming != 0 && this.codeTiming == 60) {
          this.codeTiming--;
          codeTimingTimeout = setInterval(() => {
            if(this.codeTiming >= 1){
              this.codeTiming--;
              console.log("codeTiming",this.codeTiming)
            }else{
              this.codeTiming = 60;
             clearInterval(codeTimingTimeout);
            }
          }, 1000);
          sendSmsCode({ mobile: this.phone }).then(res => {
            if (res.data) {
              this.$toast("发送成功");
            }
          });
        }
      }
    },
    // 查看协议
    privacyClick(val) {
      if (val == 1) {
        window.location.href =
          "http://lsdj-img.lanshididu.com/agreement/%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE.html?1122344";
      } else {
        window.location.href =
          "http://lsdj-img.lanshididu.com/agreement/%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.html?1122344";
      }
    }
  },
  destroyed(){
    clearInterval(codeTimingTimeout);
  }
};
</script>

<style lang="less" scoped>
.phoneLogin {
  .phoneLoginPopup {
    padding: 0.6rem;
    position: relative;
    .closeIcon {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
    }
    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .logoImg {
        width: 1.8rem;
        margin-bottom: 10px;
      }
      .title-text{
        font-weight: bold;
        font-size: .4rem;
      }
    }
    .phoneInput {
      padding: 0.4rem;
      padding-top: 0.8rem;
      p {
        display: flex;
        align-items: center;
        border-bottom: solid 1px #ebedf0;
        .inputIcon {
          font-size: 20px;
          display: inline-block;
        }
        &:first-child {
          margin-bottom: 20px;
        }
      }
      .getCodeBtn {
        font-size: 0.28rem;
        display: inline-block;
        width: 2.4rem;
        height: 0.6rem;
        text-align: center;
        line-height: 0.6rem;
        border-radius: 0.4rem;
        color: rgb(205, 60, 60);
        border: solid 0.02rem rgb(255, 134, 134);
      }
    }
    .confirmBtn {
      background: rgb(216, 47, 47);
      color: #fff;
      text-align: center;
      padding: 0.4rem;
      border-radius: 0.6rem;
      margin-top: 20px;
    }
    .privacy-div {
      display: flex;
      align-items: center;
      margin-top: 0.4rem;
      .privacy-span {
        margin-left: 0.2rem;
        font-size: 0.28rem;
        font {
          color: rgb(243, 90, 90);
        }
      }
    }
  }
  .van-cell--small {
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
