import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import store from "./store";
import "lib-flexible/flexible.js";
import "./styles/reset.css";
import VueLazyload from "vue-lazyload";
// 引入svg
import "./icons";
// 初始化vConsole
import VConsole from "vconsole";
if (process.env.NODE_ENV === 'development') {
  const vConsole = new VConsole();
}
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard);

import Vant from "vant";
import { authOpenId } from "@/api/index";
import "vant/lib/index.css";

Vue.use(Vant);
// 初始化高德地图
import AMapLoader from "@amap/amap-jsapi-loader";
Vue.use(AMapLoader);
AMapLoader.load({
  // 旧的Key
  // key: "0cc714090eea6c5f9b7d57e7d859d660",
  // 新的Key
  key: "86de3826951fe3de9e0ce9e38b9979fd",
  version: "2.0"
}).then((_) => {
  console.log('高德地图加载成功')
}).catch(e=>{
  console.log('高德地图加载失败',e)
})
window._AMapSecurityConfig = {
  // 旧的密钥
  // securityJsCode: "d7fbd0aa3375a202942240cfe0c06396"
  // 新的密钥
  securityJsCode: "5cd10e3ed3c64d8e1e064e3877d5bde8"
};
/* qs */
import qs from "qs";
Vue.prototype.qs = qs;
// ===动态改变标题
// import VueWechatTitle from "vue-wechat-title";
// Vue.use(VueWechatTitle);
/* 图片懒加载 */
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require("assets/img/imgError.png"),
  attempt: 1
});

// 点击判断是否微信授权
Vue.prototype.getwxcode = function(path, query) {
  if (
    !localStorage.getItem("authorization") &&
    !localStorage.getItem("openId")
  ) {
    let routePath = path;
    if (query) {
      routePath =
        routePath +
        "?" +
        Object.keys(query)
          .map(i => `${i}=${encodeURIComponent(query[i] || "")}`)
          .join("&");
    }
    sessionStorage.setItem("routePath", routePath);
    this.$store.commit("empowerControl", true);
    return false;
  } else if (
    localStorage.getItem("authorization") &&
    localStorage.getItem("openId")
  ) {
    if (localStorage.getItem("isBindMobile") == 2 ) {
      this.$store.commit("phoneLoginControl", true);
      return false;
    } else if (localStorage.getItem("isBindMobile") == 1) {
      sessionStorage.removeItem("routePath");
      return true;
    }else{
      this.$store.commit("phoneLoginControl", true);
      return false;
    }
  } else if (
    !localStorage.getItem("authorization") &&
    localStorage.getItem("openId")
  ) {
    authOpenId().then(res => {
      if (res.code == 10000) {
        localStorage.setItem("isBindMobile", res.data.isBindMobile);
        localStorage.setItem("authorization", res.data.authorization);
        if (res.data.isBindMobile == 2) {
          this.$store.commit("phoneLoginControl", true);
          return false;
        } else {
          sessionStorage.removeItem("routePath");
          return true;
        }
      }
    });
  }
};
// 本地调试用
// localStorage.setItem("channelCode", '');
// localStorage.setItem("authorization",'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dpblR5cGUiOiJtZW1iZXIiLCJsb2dpbklkIjoxNzg5MTc2NDA3NjM3MTQ3NjQ4LCJyblN0ciI6Ill4U2laeWZXVkxyTGkyeDdJdFJpYXZjOGxIdVV5RE9sIiwidXNlcm5hbWUiOiJtODAxNDY1NDgiLCJtb2JpbGUiOiIxODYxNDI1MTgxMSJ9.KVGXjTqj14ZX3txjdgb4RFeXxh1P2GDnMR98rfO7VYo')
// localStorage.setItem("cityCode",110100);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
